<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.13 13.26" class="fill-current">
    <defs />
    <path
      d="M6.63 13.26a.47.47 0 01-.35-.15L.15 7a.48.48 0 010-.7L6.28.15a.48.48 0 01.7 0 .48.48 0 010 .7L1.21 6.63 7 12.41a.5.5 0 01-.35.85z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>