<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.542 27.845" class="fill-current">
    <defs />
    <path
      d="M17.935 27.845c-3.756 0-9.655-1.244-11.114-2.279a1.213 1.213 0 01-.512-.987V12.744a1.21 1.21 0 01.463-.95 37.175 37.175 0 014.432-2.952c2.754-1.512 4.654-3.643 5.105-4.966C16.868 2.241 17.632 0 20.114 0a2.839 2.839 0 012.652 1.824c1.03 2.351-.054 5.616-1.865 8.2 1.219.286 2.731.656 3.619.934a4.038 4.038 0 012.983 3.195 4.552 4.552 0 01-2.146 4.231c-1.166 3.175-3.2 8.207-4.686 9.031a6.253 6.253 0 01-2.736.43zm-9.191-3.984c2.31.969 9.378 1.98 10.719 1.445.71-.641 2.492-4.676 3.736-8.1a1.211 1.211 0 01.466-.595c1.006-.672 1.527-1.441 1.429-2.111a1.692 1.692 0 00-1.3-1.231c-1.528-.478-5.277-1.3-5.315-1.313a1.209 1.209 0 01-.629-2.006c2.733-2.929 3.218-5.946 2.689-7.154-.163-.37-.332-.37-.422-.37-.6 0-.88.417-1.5 2.234-.726 2.124-3.232 4.658-6.235 6.307a33.688 33.688 0 00-3.636 2.38zm-4.788.822v-12.08a1.978 1.978 0 00-3.957 0v12.079a1.978 1.978 0 003.957 0z"
      data-name="Path 243"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>