<template>
  <div>
    <div class="text-center">
      <h2 class="text-2xl font-bold text-primary">{{ catalog.name }}</h2>
      <p class="text-sm">{{ catalog.description }}</p>
    </div>

    <div class="grid sm:grid-cols-6 grid-cols-4 gap-1 mt-1">
      <ListItem
        class="bg-white text-center"
        v-for="(item, index) in products"
        :key="index"
        :item="item"
      />

      <nuxt-link
        :to="localePath('/order')"
        class="block bg-white rounded overflow-hidden"
      >
        <div class="aspect-square bg-main mb-2 flex justify-center items-center">
          <span class="text-white font-bold text-2xl">More</span>
        </div>
        <div class="py-1 text-center">
          <p class="text-xs font-medium">更多</p>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/product/ListItem.vue";
import LoadingVue from "@luminexs/components/icons/Loading.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ListItem,
    LoadingVue,
  },
  props: {
    catalog: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    products() {
      return this.catalog.recommend;
    },
  },
};
</script>

<style lang="scss" scoped></style>
