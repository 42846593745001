<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.662 28.175"
    class=" fill-current"
  >
    <defs />
    <path
      d="M13.047 23.581a.919.919 0 01-.918.919h-1.591a.919.919 0 110-1.838h1.591a.918.918 0 01.918.919zm9.616 2.267a2.327 2.327 0 01-2.327 2.328H2.327a2.327 2.327 0 01-2.328-2.328V2.328A2.327 2.327 0 012.327.001h18.007a2.327 2.327 0 012.327 2.327zM2.447 2.45v16.844H20.21V2.45zM20.21 25.725v-4.592H2.447v4.594z"
      data-name="Path 241"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>