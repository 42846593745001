<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.28 12.28" class="fill-current">
    <defs />
    <path
      d="M7.55 6.14L12 1.71a1 1 0 000-1.42 1 1 0 00-1.41 0L6.14 4.72 1.71.29A1 1 0 00.29 1.71l4.43 4.43-4.43 4.43A1 1 0 101.71 12l4.43-4.45L10.57 12A1 1 0 0012 12a1 1 0 000-1.41z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>