<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 14" class="fill-current">
    <defs />
    <path
      fill="#522e90"
      d="M7 6H6V4h3V2H6V0H4v2H3a3 3 0 000 6h1v2H1v2h3v2h2v-2h1a3 3 0 000-6zM4 6H3a1 1 0 010-2h1zm3 4H6V8h1a1 1 0 010 2z"
      data-name="Path 245"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>