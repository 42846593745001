<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.769 20.067" class="fill-current">
    <defs />
    <path
      d="M20.344 5.001a1.5 1.5 0 11-1.064-.441 1.507 1.507 0 011.064.441zm2.338 2.1a1.508 1.508 0 101.063.442 1.515 1.515 0 00-1.063-.437zm-11.367-.749h-.952v-.867a.836.836 0 00-1.672 0v.867h-.857a.836.836 0 100 1.672h.857v.863a.836.836 0 101.672 0v-.863h.948a.836.836 0 100-1.672zm19.454 10.4a3.213 3.213 0 01-2.083 3.094c-1.28.483-3.292.266-5.094-1.789-.869-.991-2.345-2.813-3.141-3.8a23.866 23.866 0 01-10.123-.013c-.793.988-2.276 2.822-3.15 3.817a5.2 5.2 0 01-3.864 2 3.488 3.488 0 01-1.23-.216A3.213 3.213 0 010 16.751C0 12.814 2.545 6.609 3.339 4.77a3.5 3.5 0 01.347-2.775 3.9 3.9 0 012.761-2 6.513 6.513 0 012.8.8 37.552 37.552 0 0112.256.01 6.569 6.569 0 012.82-.814 3.9 3.9 0 012.761 2 3.5 3.5 0 01.346 2.775c.794 1.849 3.339 8.058 3.339 11.991zm-2.676 0c0-3.988-3.3-11.324-3.329-11.4a1.342 1.342 0 01.026-1.156.82.82 0 00.036-.744 1.718 1.718 0 00-.729-.779 8.008 8.008 0 00-1.746.711 1.339 1.339 0 01-.86.14A34.239 34.239 0 009.349 3.51a1.34 1.34 0 01-.931-.126 7.724 7.724 0 00-1.751-.708 1.722 1.722 0 00-.722.775.82.82 0 00.036.744 1.339 1.339 0 01.024 1.156c-.033.075-3.329 7.41-3.329 11.4a.562.562 0 00.351.59c.457.172 1.295-.087 2.141-1.05 1.166-1.329 3.579-4.357 3.6-4.387a1.337 1.337 0 011.419-.451 21.232 21.232 0 0010.391 0 1.336 1.336 0 011.418.451c.025.031 2.438 3.059 3.6 4.387.846.963 1.685 1.221 2.141 1.05a.561.561 0 00.356-.584z"
      data-name="Path 240"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>