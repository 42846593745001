<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 66.5"
    class="fill-current"
  >
    <defs />
    <g data-name="Layer 2">
      <path
        d="M32 66.5a8.46 8.46 0 01-6-2.5L2.49 40.51a8.5 8.5 0 0112-12L32 46 75.49 2.49a8.5 8.5 0 0112 12L38 64a8.46 8.46 0 01-6 2.5z"
        data-name="Layer 1"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
