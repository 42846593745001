<template>
  <nuxt-link
    class="block rounded overflow-hidden w-full text-xs"
    :to="localePath({ name: 'order-product-product', params: { product: item.no } })"
  >
    <div class="aspect-square w-full mb-2">
      <img
        :src="item.image || '/imgs/no_photo.jpg'"
        class="w-full h-full object-contain"
      />
    </div>
    <div class="py-1">
      <p class="line-clamp-1 font-medium">
        {{ item.name }}
      </p>
    </div>
  </nuxt-link>
</template>

<script>
import { mapActions } from "vuex";
import { PlusSmIcon } from "@vue-hero-icons/outline";

export default {
  components: {
    PlusSmIcon,
  },
  props: {
    item: Object,
  },
  methods: {
    ...mapActions({
      fetch: "cart/fetch",
      show: "specification/show",
    }),

    async addToOrder() {
      if (!this.item.buyable) return false;
      if (this.isGuest()) return;
      try {
        await this.$axios.post("/api/cart", {
          product: this.item.id,
          variations: [],
          quantity: 1,
        });
        this.fetch();
        this.$loader.success("已加入購物車");
      } catch (e) {
        this.handleErrorsMessage(e);
      }
    },
    add() {
      if (this.item.once_for_each_only) return this.show(this.item);

      this.addToOrder();
    },
  },
};
</script>

<style scoped>
.min-h-65px {
  min-height: 65px;
}
</style>
